import React from "react";
import { Link } from "react-router";
import { usePageTitle } from "./useTitle";
import { useGA } from "./useGA";
export default function Home() {
  //set default user is testuser@gmail.com
  usePageTitle();
  useGA();
  return (
    <div>
      <h1>Home</h1>
      <p>Welcome to the home page!</p>
      <Link to="/about">About</Link>
    </div>
  );
}
