import React from "react";
import { Link } from "react-router";
import { usePageTitle } from "./useTitle";
import { useGA } from "./useGA";
export default function About() {
  usePageTitle();
  useGA();
  return (
    <div>
      <h1>About</h1>
      <p>Welcome to the about page!</p>
      <Link to="/">Home</Link>
    </div>
  );
}
