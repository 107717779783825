import logo from "./logo.svg";
import "./App.css";
import AppRouter from "./route";
import { ga } from "./useGA";

function App() {
  localStorage.setItem("user", "testuser@gmail.com");
  return <AppRouter />;
}

export default App;
