import { useEffect } from "react";
import { useLocation } from "react-router";

export function usePageTitle() {
  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        document.title = "Home Page";
        break;
      case "/about":
        document.title = "About Us";
        break;
      case "/contact":
        document.title = "Contact Us";
        break;
      default:
        document.title = "My SPA";
    }
  }, [location]);
}
