import { useEffect } from "react";
import { useLocation } from "react-router";

export const useGA = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("set", "user_properties", {
        username: "PhucBui",
        membership_status: "premium",
      });
      window.gtag("event", "page_view", {
        page_path: location.pathname + location.search,
        page_location: window.location.href,
        page_title: document.title,
        user_properties: {
          username: "Phuc Bui",
          email: "phucbui@gmail.com",
        },
      });
    }
  }, [location]);
};
